body {

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;

    /*background-color: #eeeeee;*/
    text-align: center;

    margin: 0;
    padding: 0;

    /*background-color: #e1fae3;*/
    /*color: #0a360d;*/
}

.root {
    /*overflow: hidden;*/
    min-width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
    /*overflow: scroll;*/
    /*min-width: 100vw;*/
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: column;
}

footer {
    /*background-color: lightgoldenrodyellow;*/
    margin: auto 0 0 0;
    padding: 0.5rem 0 0 0;
    align-self: baseline;
    bottom: 0;
    position: sticky;
    width: 100vw;
    border-top: black solid 1px;
}
